const pathName = "";

export interface IUrlRedirection {
  navigationName: string;
  label: string;
  name: string;
  url: string;
  tab?: string;
}

export const urls: IUrlRedirection[] = [
  {
    navigationName: "dns",
    label: "new-paiement-dns",
    name: "Paiement dns",
    url: "/dns/paiements",
  },
  {
    navigationName: "dns",
    label: "new-declaration-dns",
    name: "Déclaration dns",
    url: "/dns",
  },
  {
    navigationName: "formation",
    label: "formation",
    name: "Formation",
    url: "/planning/formations",
  },
  {
    navigationName: "sensibilisation",
    label: "sensibilisation",
    name: "Sensibilisation",
    url: "/planning/sensibilisation",
  },
  {
    navigationName: "vets",
    label: "vets",
    name: "Visite d'établissement",
    url: "/planning/vets",
  },
  {
    navigationName: "carnet",
    label: "new-carnet-consultation",
    name: "Carnet de consultation",
    url: "/bulletin-consultation",
  },

  // affilies
  {
    navigationName: "rdv-specialiste",
    label: "rdv-specialiste",
    name: "Rendez-vous spécialiste",
    url: "/rdv-specialist",
  },
];
